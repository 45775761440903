<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Bayi Bilgileri</span>
        </template>
        <dealer-info />
        <dealer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span>Adres Bilgileri</span>
        </template>
        <dealer-address />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Kullanıcı Bilgileri</span>
        </template>
        <dealer-users />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import DealerInfo from '@/views/Admin/Dealers/View/DealerInfo.vue'
import DealerInvoice from '@/views/Admin/Dealers/View/DealerInvoice.vue'
import DealerAddress from '@/views/Admin/Dealers/View/DealerAddress.vue'
import DealerUsers from '@/views/Admin/Dealers/View/DealerUsers.vue'

export default {
  name: 'View',
  components: {
    BTabs,
    BTab,
    DealerInfo,
    DealerInvoice,
    DealerAddress,
    DealerUsers,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
  },
  created() {
    this.getData()
    this.getBrands()
  },
  methods: {
    getData() {
      this.$store.dispatch('dealers/getDataItem', this.$route.params.id)
    },
    getBrands() {
      this.$store.dispatch('brands/getDataList', {
        select: [
          'brands.id AS id',
          'brands.title AS title',
        ],
      })
    },
  },
}
</script>
