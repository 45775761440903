<template>
  <div>
    <validation-observer ref="simpleRules">
      <user-name />
      <user-phone />
      <user-email />
      <user-password />
      <user-status />
      <user-type />
      <save-button
        :save-action="submitForm"
      />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import UserName from '@/views/Admin/Dealers/DealerUserForm/UserName.vue'
import UserEmail from '@/views/Admin/Dealers/DealerUserForm/UserEmail.vue'
import UserPhone from '@/views/Admin/Dealers/DealerUserForm/UserPhone.vue'
import UserPassword from '@/views/Admin/Dealers/DealerUserForm/UserPassword.vue'
import UserStatus from '@/views/Admin/Dealers/DealerUserForm/UserStatus.vue'
import UserType from '@/views/Admin/Dealers/DealerUserForm/UserType.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DealerUserForm',
  components: {
    ValidationObserver,
    SaveButton,
    UserName,
    UserEmail,
    UserPhone,
    UserPassword,
    UserStatus,
    UserType,
  },
  props: {
    idDealers: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealerUsers/dataItem']
    },
    saveData() {
      return this.$store.getters['dealerUsers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.id_dealers = this.idDealers
          this.$store.dispatch('dealerUsers/saveData', this.dataItem)
            .then(response => {
              if (response) {
                this.$store.dispatch('dealerUsers/getDataList', {
                  where: {
                    'dealer_users.id_dealers': this.idDealers,
                  },
                })
              }
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
