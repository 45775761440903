<template>
  <b-card title="Fatura Bilgileri">
    <b-list-group>
      <list-item
        title="Vergi Dairesi"
        :value="dataItem.tax_office"
      />
      <list-item
        title="Vergi No"
        :value="dataItem.tax_number"
      />
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Fatura Adresi
        </div>
        <div class="font-weight-light">
          {{ dataItem.address }}
          <div>
            {{ dataItem.city }} {{ dataItem.country }}
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'
import ListItem from '@/views/Admin/Dealers/View/ListItem.vue'

export default {
  name: 'Dealers',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    ListItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
  },
}
</script>
