<template>
  <b-card title="Bayi Bilgileri">
    <b-list-group>
      <list-item
        title="Firma Adı"
        :value="dataItem.company"
      />
      <list-item
        title="Yetkili Kişi"
        :value="dataItem.name"
      />
      <list-item
        title="Telefon"
        :value="dataItem.phone"
      />
      <list-item
        title="Telefon 2"
        :value="dataItem.phone2"
      />
      <list-item
        title="Fax"
        :value="dataItem.fax"
      />
      <list-item
        title="E-Posta"
        :value="dataItem.email"
      />
      <list-item
        title="Notlar"
        :value="dataItem.notes"
      />
      <b-list-group-item v-if="dataItem.brands">
        <div class="font-weight-bold text-primary">
          Markalar
        </div>
        <div class="font-weight-light">
          <template
            v-for="(brand) in brands"
          >
            <b-badge
              v-if="brand.id.includes(dataItem.brands)"
              :key="brand.id"
              variant="light-primary"
            >
              {{ brand.title }}
            </b-badge>
          </template>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Merkez Veri Görüntüleme Yetkileri
        </div>
        <div class="font-weight-light">
          <b-badge
            v-if="dataItem.permission_customers === '1'"
            variant="light-success"
            class="mr-1"
          >
            Müşteri Kartları
          </b-badge>
          <b-badge
            v-if="dataItem.permission_interviews === '1'"
            variant="light-success"
            class="mr-1"
          >
            Görüşmeler
          </b-badge>
          <b-badge
            v-if="dataItem.permission_offers === '1'"
            variant="light-success"
            class="mr-1"
          >
            Teklifler
          </b-badge>
          <b-badge
            v-if="dataItem.permission_proforma_invoices === '1'"
            variant="light-success"
            class="mr-1"
          >
            Proforma Faturalar
          </b-badge>
          <b-badge
            v-if="dataItem.permission_services === '1'"
            variant="light-success"
            class="mr-1"
          >
            Servis Formları
          </b-badge>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import ListItem from '@/views/Admin/Dealers/View/ListItem.vue'

export default {
  name: 'Dealers',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    ListItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
    brands() {
      return this.$store.getters['brands/dataList']
    },
  },
}
</script>
