<template>
  <b-row class="match-height">
    <b-col
      v-for="address in dataList"
      :key="address.id"
      cols="12"
      md="6"
    >
      <address-card :address="address" />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-card no-body>
        <b-card-body
          class="d-flex align-items-center justify-content-center text-center"
        >
          <b-button
            v-b-toggle.address-sidebar
            variant="flat-primary"
            size="lg"
            class="p-5"
            @click="newAddress()"
          >
            <FeatherIcon
              icon="PlusCircleIcon"
              size="48"
            />
            <div class="font-medium-4 mt-1">
              Yeni Adres Ekle
            </div>
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>
    <address-sidebar />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, VBToggle,
} from 'bootstrap-vue'
import AddressCard from '@/views/Admin/Dealers/View/DealerAddress/AddressCard.vue'
import AddressSidebar from '@/views/Admin/Dealers/AddressSidebar.vue'

export default {
  name: 'DealerAddress',
  components: {
    BRow, BCol, BCard, BCardBody, BButton, AddressCard, AddressSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    dataList() {
      return this.$store.getters['dealerAddresses/dataList']
    },
    saveData() {
      return this.$store.getters['dealerAddresses/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.getData()
      } else if (val.status === false) {
        this.getData()
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    newAddress() {
      this.$store.commit('dealerAddresses/SET_DATA_ITEM', {
        id: null,
        title: null,
        name: null,
        phone: null,
        email: null,
        address: null,
        id_countries: '216',
        id_cities: null,
        id_customers: this.$route.params.id,
      })
    },
    getData() {
      this.$store.dispatch('dealerAddresses/getDataList', {
        select: [
          'dealer_address.id AS id',
          'dealer_address.title AS title',
          'dealer_address.phone AS phone',
          'dealer_address.phone2 AS phone2',
          'dealer_address.fax AS fax',
          'dealer_address.address AS address',
          'countries.title AS country',
          'cities.title AS city',
          'dealer_address.id_countries AS id_countries',
          'dealer_address.id_cities AS id_cities',
        ],
        where: {
          'dealer_address.id_dealers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
